import moment from 'moment';

export const FORMAT_TIME = 'HH:mm';
export const FORMAT_TIME_STAMP = 'HH:mm:ss';
export const FORMAT_DATEOFF = 'MM/DD/YYYY';
export const TIME_SELECT = ['5', '10', '15', '30'];
export const LOCATION_TYPE = ['MMS', 'MHS', 'Clinic'];
export const MIN_TIME_PICK = moment().startOf('date').add(7, 'hour');
export const MAX_TIME_PICK = moment().startOf('date').add(21, 'hour');

export const packageColumn = [
  {
    label: 'Package',
    stateValue: 'package',
  },
  {
    label: 'Type',
    stateValue: 'packageType',
  },
  {
    label: '',
    stateValue: 'action',
  },
];
export const FILTER_TAGS = [
  {
    value: 'Clinic',
    key: 'Clinic',
  },
  {
    value: 'Onsite',
    key: 'Onsite',
  },
];

export const FILTER_STATUSES = [
  {
    value: 'Active',
    key: 'Active',
  },
  {
    value: 'In Active',
    key: 'InActive',
  },
];
export const INTERVAL = [
  {
    value: 5,
    key: '5 min ',
  },
  {
    value: 10,
    key: '10 min',
  },
  {
    value: 15,
    key: '15 min',
  },
  {
    value: 30,
    key: '30 min',
  },
];
export const LOCATION_COLUMN = [
  {
    label: 'Location name',
    stateValue: 'name',
  },
  {
    label: 'Plato name',
    stateValue: 'platoName',
    width: 120,
  },
  {
    label: 'Address',
    stateValue: 'address',
  },
  {
    label: 'Postal code',
    stateValue: 'postalCode',
    width: 120,
  },
  {
    label: 'Type',
    stateValue: 'type',
  },
  {
    label: 'Status',
    stateValue: 'status',
  },
  {
    label: 'Action',
    stateValue: 'action',
    width: 100,
  },
];
export const COMPANY_COLUMN = [
  {
    label: 'Company name',
    stateValue: 'user',
    width: '40%',
  },
  {
    label: 'Company size',
    stateValue: 'nric',
  },
  {
    label: 'Projects',
    stateValue: 'date',
    sortBy: 'Date',
  },

  {
    label: 'Status',
    stateValue: 'status',
  },
  {
    label: 'Action',
    stateValue: 'action',
    width: 100,
  },
];

export const STEPS = [
  {
    name: 'General information',
  },
  {
    name: 'Set up time',
  },
  {
    name: 'Closing dates',
  },
  {
    name: 'Restrict package',
  },
];

export const STEPS_CONTROL = {
  back: 'Back',
  next: 'Next',
  finish: 'Create',
};

export const STEPS_CONTROL_EDIT = {
  back: 'Back',
  next: 'Next',
  finish: 'Update',
};

export const PROJECT_STEPS = [
  {
    name: 'General information',
  },
  {
    name: 'Screening locations',
  },
  {
    name: 'Available services',
  },
];

export const COLUMN_STAFFTYPE = [
  {
    label: 'Staff type',
    stateValue: 'staffType',
    width: '60%',
  },
  {
    label: 'Value',
    stateValue: 'value',
  },
  {
    label: 'Action',
    stateValue: 'action',
    width: 20,
  },
];

export const STAFF_SUBSIDY_TYPE = [
  {
    label: '%',
    value: 'Percent',
  },
  {
    label: '$',
    value: 'Value',
  },
];

export const COLUMN_PACKAGE = [
  {
    label: 'Package',
    stateValue: 'name',
  },
  {
    label: 'Type',
    stateValue: 'type',
  },
  {
    label: 'Price',
    stateValue: 'price',
    width: 160,
  },
  {
    label: 'Action',
    stateValue: 'action',
  },
];

export const COLUMN_PACKAGE_BILLING = [
  {
    label: 'Package',
    stateValue: 'name',
  },
  {
    label: 'Type',
    stateValue: 'type',
  },
  {
    label: 'Billable amount',
    stateValue: 'billableAmount',
    width: 160,
  },
];

export const COLUMN_TEST = [
  {
    label: 'Test',
    stateValue: 'name',
  },
  {
    label: 'Test code',
    stateValue: 'testCode',
    width: '20%',
  },
  {
    label: 'Station',
    stateValue: 'station',
    width: '20%',
  },
  {
    label: 'Price',
    stateValue: 'price',
    width: 160,
  },
  {
    label: '',
    stateValue: 'action',
  },
];

export const COLUMN_TEST_BILLING = [
  {
    label: 'Test',
    stateValue: 'name',
  },
  {
    label: 'Test code',
    stateValue: 'testCode',
    width: '20%',
  },
  {
    label: 'Station',
    stateValue: 'station',
    width: '20%',
  },
  {
    label: 'Billable amount',
    stateValue: 'billableAmount',
    width: 160,
  },
];

export const COLUMN_PROFILE = [
  {
    label: 'Profile',
    stateValue: 'name',
  },
  {
    label: 'Profiles',
    stateValue: 'type',
  },
  {
    label: 'Tests',
    stateValue: 'tests',
  },
  {
    label: 'Price',
    stateValue: 'price',
    width: 160,
  },
  {
    label: '',
    stateValue: 'action',
  },
];

export const COLUMN_PROFILE_BILLING = [
  {
    label: 'Profile',
    stateValue: 'name',
  },
  {
    label: 'Profiles',
    stateValue: 'type',
  },
  {
    label: 'Tests',
    stateValue: 'tests',
  },
  {
    label: 'Billable amount',
    stateValue: 'billableAmount',
    width: 160,
  },
];

export const COLUMN_PROFILE_OUTSITE = [
  {
    label: 'Profile',
    stateValue: 'name',
  },
  {
    label: 'Profiles',
    stateValue: 'type',
  },
  {
    label: 'Tests',
    stateValue: 'tests',
  },
  {
    label: 'Optional',
    stateValue: 'optional',
  },
  {
    label: '',
    stateValue: 'action',
  },
];
export const COLUMN_MEASUREMENT = [
  {
    label: 'Measurement',
    stateValue: 'name',
  },
  {
    label: 'Code',
    stateValue: 'code',
    width: '20%',
  },
  {
    label: 'Station',
    stateValue: 'station',
    width: '20%',
  },
  {
    label: 'Price',
    stateValue: 'price',
    width: 160,
  },
  {
    label: '',
    stateValue: 'action',
  },
];

export const COLUMN_MEASUREMENT_BILLING = [
  {
    label: 'Measurement',
    stateValue: 'name',
  },
  {
    label: 'Code',
    stateValue: 'code',
    width: '20%',
  },
  {
    label: 'Station',
    stateValue: 'station',
    width: '20%',
  },
  {
    label: 'Billable amount',
    stateValue: 'billableAmount',
    width: 160,
  },
];

export const COLUMN_PROCEDURES = [
  {
    label: 'Procedures',
    stateValue: 'name',
  },
  {
    label: 'Station',
    stateValue: 'station',
    width: '20%',
  },
  {
    label: 'Price',
    stateValue: 'price',
    width: 160,
  },
  {
    label: '',
    stateValue: 'action',
  },
];

export const COLUMN_PROCEDURES_BILLING = [
  {
    label: 'Procedures',
    stateValue: 'name',
  },
  {
    label: 'Station',
    stateValue: 'station',
    width: '20%',
  },
  {
    label: 'Billable amount',
    stateValue: 'billableAmount',
    width: 160,
  },
];

export const COLUMN_PROCEDURES_OUTSITE = [
  {
    label: 'Procedures',
    stateValue: 'name',
  },
  {
    label: 'Station',
    stateValue: 'station',
  },
  {
    label: 'Optional',
    stateValue: 'optional',
  },
  {
    label: '',
    stateValue: 'action',
  },
];

export const COLUMN_RADIOLOGIES = [
  {
    label: 'Radiologies',
    stateValue: 'name',
  },
  {
    label: 'Station',
    stateValue: 'station',
    width: '20%',
  },
  {
    label: 'Price',
    stateValue: 'price',
    width: 160,
  },
  {
    label: '',
    stateValue: 'action',
  },
];

export const COLUMN_RADIOLOGIES_BILLING = [
  {
    label: 'Radiologies',
    stateValue: 'name',
  },
  {
    label: 'Station',
    stateValue: 'station',
    width: '20%',
  },
  {
    label: 'Billable amount',
    stateValue: 'billableAmount',
    width: 160,
  },
];

export const COLUMN_RADIOLOGIES_OUTSITE = [
  {
    label: 'Radiologies',
    stateValue: 'name',
  },
  {
    label: 'Station',
    stateValue: 'station',
  },
  {
    label: 'Optional',
    stateValue: 'optional',
  },
  {
    label: '',
    stateValue: 'action',
  },
];

export const COLUMN_VACCINATIONS = [
  {
    label: 'Vaccinations',
    stateValue: 'name',
  },
  {
    label: 'Station',
    stateValue: 'station',
    width: '20%',
  },
  {
    label: 'Price',
    stateValue: 'price',
    width: 160,
  },
  {
    label: '',
    stateValue: 'action',
  },
];

export const COLUMN_VACCINATIONS_BILLING = [
  {
    label: 'Vaccinations',
    stateValue: 'name',
  },
  {
    label: 'Station',
    stateValue: 'station',
    width: '20%',
  },
  {
    label: 'Billable amount',
    stateValue: 'billableAmount',
    width: 160,
  },
];

export const COLUMN_REVIEWS = [
  {
    label: 'Reviews',
    stateValue: 'name',
  },
  {
    label: 'Station',
    stateValue: 'station',
    width: '20%',
  },
  {
    label: 'Price',
    stateValue: 'price',
    width: 160,
  },
  {
    label: '',
    stateValue: 'action',
  },
];

export const COLUMN_REVIEWS_BILLING = [
  {
    label: 'Reviews',
    stateValue: 'name',
  },
  {
    label: 'Station',
    stateValue: 'station',
    width: '20%',
  },
  {
    label: 'Billable amount',
    stateValue: 'billableAmount',
    width: 160,
  },
];

export const TABLE_STATE = {
  Outside: 'Outside',
  Modal: 'Modal',
};

export const DAYS_OF_WEEK = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

export const renderColumns = (originColumns, arrkeyRemove) => {
  return originColumns.filter(
    (item) => !arrkeyRemove.includes(item.stateValue)
  );
};

export const COLUMN_TIMESLOT = [
  {
    label: 'Start time',
    stateValue: 'startTime',
  },
  {
    label: 'End time',
    stateValue: 'endTime',
  },
  {
    label: 'Time interval',
    stateValue: 'interval',
  },
  {
    label: 'Quota',
    stateValue: 'quota',
  },
  {
    label: 'Status',
    stateValue: 'status',
  },
  {
    label: 'Action',
    stateValue: 'action',
  },
];

export const COLUMN_RESTRICTED_LOCATION = [
  {
    label: 'Location name',
    stateValue: 'name',
  },
  {
    label: 'Address',
    stateValue: 'address',
  },
  {
    label: '',
    stateValue: 'action',
  },
];

export const LIST_FIELD = [
  { key: 'Text area', value: 'Text' },
  { key: 'Single select', value: 'DropdownList' },
  { key: 'Multiple select', value: 'MultiSelect' },
  { key: 'Rating', value: 'Rating' },
];
export const FIELD_ARRAY_VALUE = ['DropdownList', 'MultiSelect', 'Rating'];

export const ANSWER_TYPE = {
  Text: 'Text',
  DropdownList: 'Single',
  MultiSelect: 'Multiple',
  Rating: 'Rating',
};

export const refactorListData = (data) => {
  const {
    lstTests: lstLocalTests = [],
    lstMeasurements = [],
    lstProcedures = [],
    lstRadiologies = [],
    lstVaccinations = [],
    lstReviews = [],
  } = data;

  let lstTests = [].concat(
    lstLocalTests,
    lstMeasurements,
    lstProcedures,
    lstRadiologies,
    lstVaccinations,
    lstReviews
  );
  return lstTests;
};

export const FILTER_RESTRICT_STATUSES = [
  {
    value: 'All statuses',
    key: 'All statuses',
  },
  {
    value: 'Restricted',
    key: 'Restricted',
  },
];
export const BILLING_TYPE = {
  Default: 'Default',
  Billable: 'Billable',
  Eligible: 'Eligible',
};

export const BILLING_TAB = {
  Default: 'Default',
  Billable: 'Billable',
};
