import { StatusComponent } from '../all-screenings/component/StatusComponent';
import TableCellFilted from '../common/componentUI/TableCellFilted';
import counsellorsManagementDispatcher from './action';
import GroupToolbar from './component/GroupToolbar';
import PublicCounsellorProfile from './component/PublicCounsellorProfile';
import {
  ACTIVE_STATUSES,
  ALL_COUNSELLOR_STATUSES,
  APPROVE_STATUSES,
  COLUMN_COUNSELLORS,
  COUNSELLOR_STATUS,
  COUNSELLOR_STATUS_COLORS,
  TYPE_MODAL,
} from './constants';
import {
  ActivateIcon,
  BanIcon,
  CheckmarkCircleIcon,
  CloseCircle,
  CreateNewIcon,
  DeactivateIcon,
  DeleteModalIcon,
  EditIcon,
  MincAdmin,
  NoDataCounsellors,
  ShieldCheckmarkIcon,
} from '@/assets/svg';
import {
  formatTotalPrice,
  getNationalNumber,
  handleSelectAll,
  momentFormatted,
} from '@/helpers';
import CustomMenu from '@/new-components/CustomMenu';
import CustomMenuActions from '@/new-components/CustomMenuActions';
import ModalAddOrEditCounsellor from '@/new-components/CustomModal/ModalAddOrEditCounsellor';
import ModalConfirmation from '@/new-components/CustomModal/ModalConfirmation';
import customToast from '@/new-components/CustomNotification';
import CustomPaperContainer from '@/new-components/CustomPaperContainer';
import CustomHeader from '@/new-components/CustomPaperContainer/CustomHeader';
import CustomTable from '@/new-components/CustomTable';
import CustomTooltip from '@/new-components/CustomTooltip';
import { validateMobile } from '@/utils/validation';
import {
  Button,
  Drawer,
  IconButton,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { MoreVertRounded } from '@material-ui/icons';
import { parsePhoneNumber } from 'libphonenumber-js';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const CounsellorsManagement = () => {
  const { list: data, paging } = useSelector((state) => state.counsellors);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState({});
  const [typeModal, setTypeModal] = useState(null);

  const [sortOption, setSortOption] = useState({});
  const [searchKey, setSearchKey] = useState('');
  const [filterOption, setFilterOption] = useState({});
  const [loading, setLoading] = useState(false);

  const [counsellorsStatuses, setCounsellorsStatuses] = useState([]);
  const [showCounsellorProfile, setShowCounsellorProfile] = useState(false);
  const [showCounsellorModal, setShowCounsellorModal] = useState(false);
  const [showDeleteCounsellorModal, setDeleteShowCounsellorModal] =
    useState(false);
  const [showActiveCounsellorModal, setActiveCounsellorModal] = useState(false);

  const onFilterOptionChange = (key, setState) => (e) => {
    let value = e.target.value;

    if (key === 'counsellorsStatuses') {
      value = handleSelectAll(
        value,
        ALL_COUNSELLOR_STATUSES.map((item) => item.key),
        counsellorsStatuses
      );
      let activeStatuses = [];
      let approveStatuses = [];
      value.forEach((filterItem) => {
        if (filterItem === APPROVE_STATUSES.PENDING) {
          approveStatuses = approveStatuses.concat(APPROVE_STATUSES.PENDING);
        }
        if (filterItem === APPROVE_STATUSES.REJECT) {
          approveStatuses = approveStatuses.concat(APPROVE_STATUSES.REJECT);
        }
        if (filterItem === ACTIVE_STATUSES.ACTIVE) {
          approveStatuses = approveStatuses.concat(APPROVE_STATUSES.APPROVED);
          activeStatuses = activeStatuses.concat(ACTIVE_STATUSES.ACTIVE);
        }

        if (filterItem === ACTIVE_STATUSES.IN_ACTIVE) {
          approveStatuses = approveStatuses.concat(APPROVE_STATUSES.APPROVED);
          activeStatuses = activeStatuses.concat(ACTIVE_STATUSES.IN_ACTIVE);
        }
      });

      activeStatuses = activeStatuses.filter(
        (item, pos, self) => self.indexOf(item) === pos
      );
      approveStatuses = approveStatuses.filter(
        (item, pos, self) => self.indexOf(item) === pos
      );

      setFilterOption({
        ...filterOption,
        activeStatuses,
        approveStatuses,
      });
    } else
      setFilterOption({
        ...filterOption,
        [key]: value,
      });

    setState && setState(value);
  };

  const onResetFilterOps = () => {
    setCounsellorsStatuses([]);
    setFilterOption({});
  };

  const onShowCounsellorModal = (type) => {
    setShowCounsellorModal(true);
    setTypeModal(type);
  };

  const renderToast = (data, type) => {
    switch (type) {
      case TYPE_MODAL.Create:
        return customToast(
          'success',
          <span>
            Counsellor <strong style={{ fontWeight: 600 }}>{data}</strong> has
            been successully created.
          </span>,
          'New counsellor created'
        );

      case TYPE_MODAL.Edit:
        return customToast(
          'success',
          <span>
            Counsellor <strong style={{ fontWeight: 600 }}>{data}</strong> has
            been successully updated.
          </span>,
          'Counsellor updated'
        );

      default:
        break;
    }
  };

  const handleUpdateCounsellor = (type, data) => {
    setLoading(true);
    switch (type) {
      case TYPE_MODAL.Create:
        counsellorsManagementDispatcher.createCounsellor(
          data,
          () => {
            setLoading(false);
            fetchData(searchKey, paging, sortOption, filterOption);
            renderToast(`${data.firstName} ${data.lastName}`, type);
            setShowCounsellorModal(false);
          },
          () => {
            setLoading(false);
          }
        );
        break;

      case TYPE_MODAL.Edit:
        counsellorsManagementDispatcher.editCounsellor(
          data.id,
          {
            ...data,
            mobile: data.mobile ? data.mobile : undefined,
            countryCode: data.countryCode,
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            pricePerHour: data.pricePerHour,
          },
          () => {
            setLoading(false);
            fetchData(searchKey, paging, sortOption, filterOption);
            renderToast(`${data.firstName} ${data.lastName}`, type);
            setShowCounsellorModal(false);
          },
          () => {
            setLoading(false);
          }
        );
        break;

      default:
        break;
    }
  };

  const onApproveCounsellor = (data) => {
    setLoading(true);
    counsellorsManagementDispatcher.editCounsellor(
      data.id,
      {
        ...data,
        mobile: data.mobile ? data.mobile : undefined,
        countryCode: data.countryCode,
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        pricePerHour: data.pricePerHour,
        isAdminApprove: true,
      },
      () => {
        setLoading(false);
        fetchData(searchKey, paging, sortOption, filterOption);
        setShowCounsellorModal(false);
        customToast('success', 'Approve successfully');
      },
      () => {
        setLoading(false);
      }
    );
  };

  const onRejectCounsellor = () => {
    counsellorsManagementDispatcher.approveCounsellor(
      selectedItem.id,
      false,
      () => {
        fetchData(searchKey, paging, sortOption, filterOption);
        setShowCounsellorModal(false);
      }
    );
  };

  const onDeleteCounsellor = () => {
    counsellorsManagementDispatcher.deleteCounsellor(selectedItem.id, () => {
      fetchData(searchKey, paging, sortOption, filterOption);
      setDeleteShowCounsellorModal(false);
    });
  };

  const onActivateCounsellor = () => {
    const activeStatus =
      selectedItem.activeStatus === 'Active' ? 'InActive' : 'Active';
    counsellorsManagementDispatcher.activateCounsellor(
      selectedItem.id,
      activeStatus,
      () => {
        fetchData(searchKey, paging, sortOption, filterOption);
        setActiveCounsellorModal(false);
      }
    );
  };

  const fetchData = async (
    search = searchKey,
    pagingData = paging,
    sortOptionData = sortOption,
    filterOptionData = filterOption
  ) => {
    counsellorsManagementDispatcher.getData(
      search,
      pagingData,
      ...[sortOptionData, filterOptionData].filter((i) => i)
    );
  };

  const debounceLoadData = useCallback(
    debounce((searchKey, paging, sortOption, filterOption) => {
      fetchData(
        searchKey,
        {
          pageIndex: 0,
          pageSize: paging.pageSize,
        },
        sortOption,
        filterOption
      );
    }, 500),
    []
  );

  useEffect(() => {
    debounceLoadData(searchKey, paging, sortOption, filterOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey, sortOption, filterOption]);

  useEffect(() => {
    return () => {
      counsellorsManagementDispatcher.resetData();
    };
  }, []);

  const renderListActions = useCallback(() => {
    const counsellorStatus = renderCounsellorStatuses(selectedItem);
    let listActions = [];

    switch (counsellorStatus) {
      case COUNSELLOR_STATUS.Pending:
        listActions = listActions.concat([
          {
            key: 'verify',
            icon: ShieldCheckmarkIcon,
            label: 'Verify counsellor',
            styles: { width: 18, height: 18 },
            onClick: () => {
              setAnchorEl(null);
              setSelectedItem(selectedItem);
              onShowCounsellorModal(TYPE_MODAL.Verify);
            },
          },
          {
            key: 'delete',
            icon: CloseCircle,
            label: 'Delete counsellor',
            color: '#DB3D49',
            styles: { width: 18, height: 18 },
            onClick: () => {
              setAnchorEl(null);
              setSelectedItem(selectedItem);
              setDeleteShowCounsellorModal(true);
            },
          },
        ]);
        break;
      case COUNSELLOR_STATUS.Active:
        listActions = listActions.concat([
          {
            key: 'edit',
            icon: EditIcon,
            label: 'Edit counsellor',
            styles: { width: 18, height: 18 },
            onClick: () => {
              setAnchorEl(null);
              setSelectedItem(selectedItem);
              onShowCounsellorModal(TYPE_MODAL.Edit);
            },
          },
          {
            key: 'deactivate',
            icon: BanIcon,
            label: 'Deactivate counsellor',
            styles: { width: 18, height: 18 },
            onClick: () => {
              setAnchorEl(null);
              setSelectedItem(selectedItem);
              setActiveCounsellorModal(true);
            },
          },
          {
            key: 'delete',
            icon: CloseCircle,
            label: 'Delete counsellor',
            color: '#DB3D49',
            styles: { width: 18, height: 18 },
            onClick: () => {
              setAnchorEl(null);
              setSelectedItem(selectedItem);
              setDeleteShowCounsellorModal(true);
            },
          },
        ]);
        break;

      case COUNSELLOR_STATUS.InActive:
        listActions = listActions.concat([
          {
            key: 'edit',
            icon: EditIcon,
            label: 'Edit counsellor',
            styles: { width: 18, height: 18 },
            onClick: () => {
              setAnchorEl(null);
              setSelectedItem(selectedItem);
              onShowCounsellorModal(TYPE_MODAL.Edit);
            },
          },
          {
            key: 'activate',
            icon: CheckmarkCircleIcon,
            label: 'Activate counsellor',
            styles: { width: 18, height: 18 },
            onClick: () => {
              setAnchorEl(null);
              setSelectedItem(selectedItem);
              setActiveCounsellorModal(true);
            },
          },
          {
            key: 'delete',
            icon: CloseCircle,
            label: 'Delete counsellor',
            color: '#DB3D49',
            styles: { width: 18, height: 18 },
            onClick: () => {
              setAnchorEl(null);
              setSelectedItem(selectedItem);
              setDeleteShowCounsellorModal(true);
            },
          },
        ]);
        break;
      default:
        break;
    }

    return listActions;
  }, [selectedItem]);

  const renderCounsellorStatuses = (selectedItem) => {
    if (!selectedItem) return;
    const { activeStatus, approveStatus } = selectedItem;

    if (approveStatus === APPROVE_STATUSES.APPROVED)
      return COUNSELLOR_STATUS[activeStatus];
    return COUNSELLOR_STATUS[approveStatus];
  };

  const TableHeader = () => (
    <TableHead>
      <TableRow>
        {COLUMN_COUNSELLORS.map((item) =>
          item.sortBy ? (
            <TableCellFilted
              key={item.stateValue}
              label={item.label}
              stateValue={item.stateValue}
              sortBy="consultantDate"
              sortOption={sortOption}
              onSortChange={() => {
                let newSortOption = {
                  by: 'Date',
                  dir: sortOption.dir !== 'asc' ? 'asc' : 'desc',
                };
                setSortOption(newSortOption);
              }}
              style={{
                minWidth: item.minWidth || 'unset',
                width: item.width || 'unset',
              }}
              isHidden={false}
            />
          ) : (
            <TableCellFilted
              key={item.stateValue}
              label={item.label}
              stateValue={item.stateValue}
              style={{
                minWidth: item.minWidth || 'unset',
                width: item.width || 'unset',
              }}
              isHidden={false}
            />
          )
        )}
      </TableRow>
    </TableHead>
  );

  const renderPublicIconColor = (status, isMissing) => {
    const fullInfoActive = '#AD5E99';
    const fullInfoIntive = '#AD5E994D';
    const missingInfoActive = '#666666';
    const missingInfoIntive = '#6666664D';

    if (status === 'Active' && !isMissing) return fullInfoActive;
    if (status === 'Active' && isMissing) return missingInfoActive;
    if (status !== 'Active' && !isMissing) return fullInfoIntive;
    if (status !== 'Active' && isMissing) return missingInfoIntive;
  };

  const renderTableBody = (row) => {
    // const isValidPhone = validateMobile(row.mobile, row?.countryCode);

    return (
      <>
        <TableCell>
          <CustomTooltip content={row.fullName || '—'} />
        </TableCell>
        {/* <TableCell>
          {row.mobile && row?.countryCode && isValidPhone
            ? parsePhoneNumber(
                getNationalNumber(row?.mobile, row?.countryCode, true)
              ).formatInternational()
            : '—'}
        </TableCell> */}
        <TableCell>
          <CustomTooltip content={row.email || '—'} />
        </TableCell>
        <TableCell>
          {row.pricePerHour ? formatTotalPrice(row.pricePerHour) : '—'}{' '}
        </TableCell>
        <TableCell>{row.totalCounsel || '—'}</TableCell>
        <TableCell>
          {row.lastCounselDate
            ? momentFormatted(row.lastCounselDate, 'DD/MM/YYYY')
            : '—'}
        </TableCell>

        <TableCell>
          <StatusComponent
            status={renderCounsellorStatuses(row)}
            filterList={COUNSELLOR_STATUS}
            filterListColor={COUNSELLOR_STATUS_COLORS}
          />
        </TableCell>

        <TableCell>
          <Tooltip title="Public profile">
            <IconButton
              onClick={() => {
                setSelectedItem(row);
                setShowCounsellorProfile(true);
              }}
              disabled={
                renderCounsellorStatuses(row) !== COUNSELLOR_STATUS.Active
              }
            >
              <MincAdmin
                color={renderPublicIconColor(
                  renderCounsellorStatuses(row),
                  row.isMissingInfomation
                )}
              />
            </IconButton>
          </Tooltip>

          <IconButton
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
              setSelectedItem(row);
            }}
            disabled={
              renderCounsellorStatuses(row) === COUNSELLOR_STATUS.Reject
            }
          >
            <MoreVertRounded />
          </IconButton>
        </TableCell>
      </>
    );
  };

  return (
    <>
      <CustomMenu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <CustomMenuActions listActions={renderListActions()} />
      </CustomMenu>
      <CustomPaperContainer
        header={
          <CustomHeader
            searchPlaceholder="Search by name or email..."
            title="Counsellors"
            setSearchKey={setSearchKey}
            searchKey={searchKey}
            filterOption={filterOption}
            setFilterOption={setFilterOption}
            sortOption={sortOption}
            fetchData={fetchData}
            renderToolbar={() => (
              <GroupToolbar
                filterOption={filterOption}
                onFilterOptionChange={onFilterOptionChange}
                onResetFilterOps={onResetFilterOps}
                counsellorsStatuses={counsellorsStatuses}
                setCounsellorsStatuses={setCounsellorsStatuses}
              />
            )}
            renderButton={() => (
              <Button
                color="primary"
                variant="contained"
                startIcon={<CreateNewIcon />}
                onClick={() => {
                  setSelectedItem(null);
                  onShowCounsellorModal(TYPE_MODAL.Create);
                }}
              >
                Create new
              </Button>
            )}
          />
        }
      >
        <CustomTable
          data={data}
          paging={paging}
          header={TableHeader}
          renderRow={(row, i) => renderTableBody(row)}
          noDataHelperText="No counsellors"
          noDataHelperSubText="Please create a new counsellor profile."
          Icon={NoDataCounsellors}
          totalCols={COLUMN_COUNSELLORS.length}
          fetchData={fetchData}
        />
      </CustomPaperContainer>

      <Drawer
        anchor="right"
        open={showCounsellorProfile}
        onClose={() => setShowCounsellorProfile(false)}
        disableBackdropClick
        PaperProps={{
          style: {
            width: '50%',
            minWidth: 768,
          },
        }}
      >
        <PublicCounsellorProfile
          onClose={() => {
            setShowCounsellorProfile(false);
            fetchData(searchKey, paging, sortOption, filterOption);
          }}
          selectedItem={selectedItem}
        />
      </Drawer>

      {showCounsellorModal && (
        <ModalAddOrEditCounsellor
          open={showCounsellorModal}
          onClose={() => setShowCounsellorModal(false)}
          typeModal={typeModal}
          selectedItem={selectedItem}
          onSubmit={handleUpdateCounsellor}
          onApprove={onApproveCounsellor}
          onReject={onRejectCounsellor}
          loading={loading}
        />
      )}

      {showDeleteCounsellorModal && (
        <ModalConfirmation
          open={showDeleteCounsellorModal}
          onClick={onDeleteCounsellor}
          onClose={() => setDeleteShowCounsellorModal(false)}
          mainContent="Delete counsellor"
          subContent="Are you sure you want to delete this counsellor?"
          cancelLabel="Cancel"
          confirmLabel="Delete"
          confirmColor="red"
          Icon={<DeleteModalIcon />}
        />
      )}

      {showActiveCounsellorModal && (
        <ModalConfirmation
          open={showActiveCounsellorModal}
          onClick={onActivateCounsellor}
          onClose={() => setActiveCounsellorModal(false)}
          mainContent={`${
            selectedItem.activeStatus === COUNSELLOR_STATUS.Active
              ? 'Deactivate'
              : 'Activate'
          } counsellor`}
          subContent={`Are you sure you want to ${
            selectedItem.activeStatus === COUNSELLOR_STATUS.Active
              ? 'deactivate'
              : 'activate'
          } this counsellor?`}
          cancelLabel="Cancel"
          confirmLabel={
            selectedItem.activeStatus === COUNSELLOR_STATUS.Active
              ? 'Deactivate'
              : 'Activate'
          }
          Icon={
            selectedItem.activeStatus === COUNSELLOR_STATUS.Active ? (
              <DeactivateIcon />
            ) : (
              <ActivateIcon />
            )
          }
        />
      )}
    </>
  );
};

export default CounsellorsManagement;
