import {
  AllUser,
  AllUserActive,
  Teleconsult,
  TeleconsultInactive,
  Clinic,
  ClinicInactive,
  HealthScreening,
  HealthScreeningInactive,
  TeleFit,
  TeleFitInactive,
  MincAdmin,
  MincAdminInactive,
  TeleCounselInactive,
  TeleCounsel,
} from '@/assets/svg';
import { urlLabel } from '@/enum/PermissionEnum';

export const renderGroupMenu = () => [
  {
    title: 'All Users',
    privilege: 'HomescreenAllUsers',
    value: urlLabel.userDetails,
    path: urlLabel.userDetails,
    isItem: true,
    iconInActive: AllUser,
    iconActive: AllUserActive,
    children: [
      {
        value: urlLabel.editUserDetail,
      },
    ],
  },

  {
    title: 'Teleconsult',
    isGroupItem: true,
    isOpen: false,
    value: 'Teleconsult',
    iconInActive: TeleconsultInactive,
    iconActive: Teleconsult,
    children: [
      {
        title: 'Doctor Management',
        value: urlLabel.doctorManagement,
        privilege: 'DoctorManagement',
      },
      {
        title: 'Patient Management',
        value: urlLabel.patientManagement,
        privilege: 'PatientManagement',
      },
      {
        title: 'CA Management',
        value: urlLabel.caManagement,
        privilege: 'CAManagement',
      },
      {
        title: 'Consult Room',
        value: urlLabel.waitingRoom,
        privilege: 'ConsultRoom',
      },
      {
        title: 'Visit Records',
        value: urlLabel.consultList,
        privilege: 'VisitRecords',
      },
      {
        title: 'Consult List',
        value: urlLabel.meetingHistory,
        privilege: 'ConsultList',
      },
      {
        title: 'Appointment Config',
        privilege: 'AppointmentConfig',
        value: urlLabel.bookingManagement,
      },
      {
        title: 'Appointments',
        privilege: 'Appointments',
        value: urlLabel.appointmentManagement,
      },
      {
        title: 'MHS Management',
        privilege: 'MHSManagement',
        value: urlLabel.mhsManagement,
      },
      {
        title: 'Teleconsult Analytics',
        privilege: 'TeleconsultAnalytics',
        value: urlLabel.teleconsultAnalytics,
      },
    ],
  },

  {
    title: 'Counselling',
    isGroupItem: true,
    isOpen: false,
    value: 'Counselling',
    iconInActive: TeleCounselInactive,
    iconActive: TeleCounsel,
    children: [
      {
        title: 'Queue',
        value: urlLabel.telecounselQueue,
        privilege: 'CounselQueue',
      },
      {
        privilege: 'CounselAll',
        value: urlLabel.telecounselAllCounsels,
        title: 'All counsellings',
      },
      {
        title: 'Appointments',
        value: urlLabel.telecounselAppointments,
        privilege: 'CounselAppointments',
      },
      {
        title: 'Resources management',
        privilege: 'ResourcesManagement',
        value: 'Resources Management',
        isOpen: false,
        hasSubmenu: true,
        children: [
          {
            title: 'Counsellors',
            privilege: 'CounsellorManagement',
            value: urlLabel.telecounseRMCounsellors,
          },
          {
            title: 'Counsellor executives',
            privilege: 'CounsellorExecutivesManagement',
            value: urlLabel.telecounseRMCounsellorExecutives,
          },
        ],
      },
      {
        title: 'Appointment configuration',
        privilege: 'CounsellorConfig',
        value: urlLabel.telecounselAppointmentConfig,
      },
    ],
  },

  {
    title: 'Clinic',
    isOpen: false,
    isGroupItem: true,
    value: 'Clinic',
    iconInActive: ClinicInactive,
    iconActive: Clinic,
    children: [
      {
        title: 'Clinic appointments',
        privilege: 'ClinicAppointments',
        value: urlLabel.clinicAppointments,
      },
      {
        title: 'Clinic setup',
        privilege: 'ClinicSetUpMenu',
        value: 'Clinic SetUp',
        isOpen: false,
        hasSubmenu: true,
        children: [
          {
            title: 'Clinic locations',
            privilege: 'ClinicSetUp',
            value: urlLabel.clinicManagement,
          },
          {
            title: 'Clinic services',
            privilege: 'ClinicServices',
            value: urlLabel.clinicServices,
          },
        ],
      },

      {
        title: 'Queue Request',
        privilege: 'QueueRequest',
        value: urlLabel.queueManagement,
      },
    ],
  },
  {
    title: 'Health Screening',
    value: 'TeleScreen',
    isGroupItem: true,
    isOpen: false,
    iconInActive: HealthScreeningInactive,
    iconActive: HealthScreening,
    children: [
      {
        title: 'Upcoming',
        privilege: 'Upcoming',
        value: urlLabel.upcomingHealthScreening,
        children: [
          {
            value: urlLabel.upcomingEditAppointment,
          },
        ],
      },
      {
        title: 'All Screenings',
        privilege: 'HealthScreeningAppointments',
        value: urlLabel.allScreenings,
        children: [
          { value: urlLabel.aSEditAppointment },
          { value: urlLabel.aSviewPatientRegistration },
          { value: urlLabel.aSRegisterPatient },
          { value: urlLabel.aSEditPackage },
          { value: urlLabel.aSCreateAppointment },
          { value: urlLabel.aSScreeningProgress },
          { value: urlLabel.aSPatientRegistration },
          { value: urlLabel.aSCreateFollowUpAppointment },
          { value: urlLabel.aSRescheduleAppointment },
        ],
      },
      {
        title: 'E-order',
        privilege: 'EOrder',
        value: urlLabel.orderListExport,
      },
      {
        title: 'Closing Report',
        privilege: 'ClosingReport',
        value: urlLabel.siteClosingReport,
      },
      {
        title: 'Backroom',
        privilege: 'Backroom',
        value: urlLabel.backroomModule,
      },
      {
        title: 'Queue',
        privilege: 'Queue',
        value: urlLabel.screenQueue,
      },
      {
        title: 'Setup',
        privilege: 'Setup',
        value: 'Set Up',
        isOpen: false,
        hasSubmenu: true,
        children: [
          {
            title: 'Location',
            privilege: 'SiteConfig',
            value: urlLabel.locationManagement,
            children: [
              { value: urlLabel.locationManagementCreate },
              { value: urlLabel.locationManagementEdit },
            ],
          },
          {
            title: 'Company',
            privilege: 'CompanyManagement',
            value: urlLabel.companyManagement,
            children: [
              { value: urlLabel.createCompany },
              { value: urlLabel.editCompany },
              { value: urlLabel.createProject },
              { value: urlLabel.editProject },
              { value: urlLabel.projectManage },
            ],
          },
        ],
      },
      {
        title: 'Configuration',
        privilege: 'Configuration',
        value: urlLabel.configuration,
        isOpen: false,
        children: [
          { value: urlLabel.configPackages },
          { value: urlLabel.configProfiles },
          { value: urlLabel.configOtherServices },
          { value: urlLabel.configTimeslotTemplate },
          { value: urlLabel.configStations },
          { value: urlLabel.configLifestyleSurvey },
          { value: urlLabel.configConsentForm },
          { value: urlLabel.configMotherhood },
        ],
      },
    ],
  },
  {
    title: 'TeleFit',
    isOpen: false,
    isGroupItem: true,
    value: 'TeleFit',
    iconInActive: TeleFitInactive,
    iconActive: TeleFit,
    children: [
      {
        title: 'Workout',
        value: urlLabel.categoryManagement,
        privilege: 'Workout',
        isOpen: false,
        hasSubmenu: true,
        children: [
          {
            title: 'Categories',
            value: urlLabel.categoryManagement,
            privilege: 'Categories',
          },
          {
            title: 'Livestream',
            value: urlLabel.livestreamManagement,
            privilege: 'Livestream',
            children: [
              {
                value: urlLabel.livestreamSectionDetail,
              },
            ],
          },
          {
            title: 'On Demand',
            value: urlLabel.onDemandManagement,
            privilege: 'OnDemand',
          },
          {
            title: 'Studio Classes',
            value: urlLabel.studioClassesManagement,
            privilege: 'StudioClasses',
          },
        ],
      },
      {
        title: 'Upcoming Workouts',
        privilege: 'UpcomingWorkout',
        value: urlLabel.upcomingWorkoutManagement,
      },
      {
        title: 'Studio Management',
        privilege: 'StudioManagement',
        value: urlLabel.studioManagement,
      },
      {
        title: 'Trainer Management',
        privilege: 'TrainerManagement',
        value: urlLabel.trainerManagement,
      },
      {
        title: 'Internal Trainer Management',
        privilege: 'InternalTrainerManagement',
        value: urlLabel.userManagement,
      },
    ],
  },
  {
    title: 'Minc Admin',
    value: 'MincAdmin',
    isGroupItem: true,
    isOpen: false,
    iconInActive: MincAdminInactive,
    iconActive: MincAdmin,
    children: [
      {
        title: 'User Management',
        privilege: 'UserManagement',
        value: urlLabel.adminManagement,
      },
      {
        title: 'Stuck Accounts',
        privilege: 'StuckAccounts',
        value: urlLabel.stuckAccounts,
      },
      {
        title: 'Roles Management',
        privilege: 'RoleManagement',
        value: urlLabel.rolesManagement,
      },
      {
        title: 'E-commerce Management',
        privilege: 'EcommerceManagement',
        value: urlLabel.ecommerceManagement,
      },
      {
        title: 'Promo Code',
        privilege: 'PromoCode',
        value: urlLabel.promotion,
      },
      {
        title: 'Deletion Worklist',
        privilege: 'DeletionWorklist',
        value: urlLabel.deletionRequest,
      },
      {
        title: 'Banner Management',
        privilege: 'BannerManagement',
        value: urlLabel.bannerManagement,
      },
      {
        title: 'Marketing messages',
        privilege: 'MarketingMessage',
        value: urlLabel.marketingMessages,
      },
      {
        title: 'Lab Result',
        privilege: 'LabResult',
        value: urlLabel.labResult,
      },
      {
        title: 'Import HS Appointments',
        privilege: 'ImportHSAppointments',
        value: urlLabel.importHSAppointments,
      },
      {
        title: 'API Support',
        privilege: 'APISupport',
        value: urlLabel.apiSupport,
        isSupport: true,
      },
    ],
  },
];
